import { Routes, Route } from 'react-router-dom';
import Main from './pages/main';
import Second from './pages/second';

function App() {
  console.log('urlurl', process.env.REACT_APP_BACKEND_URL);
  return (
    <Routes>
      <Route path={'/'} element={<Main />} />
      <Route path={'/second'} element={<Second />} />
    </Routes>
  );
}

export default App;
